import PhotoSwipeLightbox from '../Lib/photoswipe/photoswipe-lightbox.esm.js';
import PhotoSwipeDynamicCaption from '../Lib/photoswipe/photoswipe-dynamic-caption-plugin.esm.js';

const commonConfig = {
    closeTitle: window.c3?.translations?.photoswipe?.close ?? 'Close the gallery',
    zoomTitle: window.c3?.translations?.photoswipe?.zoom ?? 'Zoom the image',
    arrowPrevTitle: window.c3?.translations?.photoswipe?.prev ?? 'Go to the previous image',
    arrowNextTitle: window.c3?.translations?.photoswipe?.next ?? 'Go to the next image',
    errorMsg: window.c3?.translations?.photoswipe?.error ?? 'The image cannot be loaded',
    indexIndicatorSep: ` ${window.c3?.translations?.photoswipe?.indexIndicator ?? 'of'} `,
};

const PhotoSwipe = () => import('../Lib/photoswipe/photoswipe.esm.js');

/*
<a href="/path/to/big/image.jpg"
   rel="lightbox"
   data-pswp-id="66"      < The ID of the lightbox group
   data-pswp-width="1080"   < The dimensions of the big image
   data-pswp-height="1080"  <-|
>
    <img ... >
</a>
 */

function init() {
    const lightboxElements = document.querySelectorAll('[rel^="lightbox"]');
    /** @type {Set<string>} */
    const lightboxGroupIds = new Set();

    for (const element of lightboxElements) {
        // add lightbox id (for legacy rendering)
        if (!element.dataset.pswpId) {
            const matches = element.rel.match(/lightbox\[(?<id>\d+)]/);
            element.dataset.pswpId = matches.groups.id;
        }

        // add lightbox dimensions (for legacy rendering)
        if (!element.dataset.pswpWidth) {
            const image = element.querySelector('img');
            element.dataset.pswpWidth = image.width.toString();
            element.dataset.pswpHeight = image.height.toString();
        }

        lightboxGroupIds.add(element.dataset.pswpId);
    }

    for (const id of lightboxGroupIds) {
        const lightbox = new PhotoSwipeLightbox({
            ...commonConfig,
            gallery: 'body',
            children: `[data-pswp-id="${id}"]`,
            pswpModule: PhotoSwipe
        });

        /*
         * caption priority:
         * 1. data-pswp-description="description abc"
         * 2. data-pswp-description-selector="#some-description-selector"
         * 3. the `figcaption` of the current `figure` element (if we are in a `figure` element)
         */
        new PhotoSwipeDynamicCaption(lightbox, {
            captionContent: (slide) => {
                return slide.data.element.dataset.pswpDescription
                    ?? getDescriptionBySelector(slide)
                    ?? slide.data.element.closest('figure')?.querySelector('figcaption')?.textContent
                    ?? '';
            }
        });

        lightbox.init();
    }
}

/**
 * @param {{data: {element: HTMLAnchorElement}}} slide
 * @return {string|null}
 */
function getDescriptionBySelector(slide) {
    const selector = slide.data.element.dataset.pswpDescriptionSelector;
    if (!selector) {
        return null;
    }
    return document.querySelector(selector)?.textContent;
}

export {init, PhotoSwipeLightbox, PhotoSwipe};